import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit, computed, effect, inject, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MessageService } from 'primeng/api';
import { ToastModule, ToastPositionType } from 'primeng/toast';
import { Subscription } from 'rxjs';
import { untilDestroyed } from 'src/app/core/helpers/until-destroyed';
import { ToastService } from 'src/app/core/services/toast.service';
@Component({
    selector: 'app-toast',
    standalone: true,
    imports: [
        CommonModule,
        ToastModule,
    ],
    templateUrl: './toast.component.html',
    styleUrl: './toast.component.scss',
})
export class ToastComponent implements OnInit {
    private subscription: Subscription | undefined;
    toastMsg = signal({} as ToastMessage);
    position = signal('top-right' as ToastPositionType);
    toastSeverity = signal('');
    styleClass = signal('');
    private untilDestroyed = untilDestroyed();
    private injector = inject(Injector);
    toastService = inject(ToastService);
    toastMessage$ = computed(() => { return this.toastService.toastMessage$() });

    constructor(
        private cdr: ChangeDetectorRef) {
        effect((e) => {
        });
    }
    // @Input() message: ToastMessage | null = null; // Interface to define message structure

    ngOnInit() {

        toObservable(this.toastMessage$, {
            injector: this.injector
        }).pipe(this.untilDestroyed()).subscribe({
            next: (toastMsg: any) => {
                if (toastMsg) {
                    this.styleClass.set('');

                    if (toastMsg.toastType) {
                        if (toastMsg.toastType === 'fullWidth') {
                            this.styleClass.set('bottom-0 left-0 w-full');
                        }
                    }
                    if (toastMsg.position) {
                        this.position.set(toastMsg.position);
                    }
                    if (toastMsg.severity) {
                        this.toastSeverity.set(toastMsg.severity);
                    }
                    console.log(toastMsg);
                    toastMsg.life = 5000;
                    this.cdr.detectChanges();
                    this.toastService.showToast(toastMsg);
                    // this.messageService.add({ severity: toastMsg.severity, summary: toastMsg.summary, detail: toastMsg.detail });
                    // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Message Content' });
                    setTimeout(() => {
                        const toast = {
                            key: toastMsg.key || 'tc', life: 5000, severity: toastMsg.severity, summary: toastMsg.summary,
                            detail: toastMsg.detail
                        };
                        this.toastService.showToast(toast);
                        this.toastService.set(null);
                    }, 50);
                }
            }
        });



    }

    ngAfterViewInit() {


    }

    showSuccess() {
        // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Message Content' });
    }

    ngOnDestroy() {
        // this.subscription!.unsubscribe();
    }

    showMessage(summary: string, detail: string, type: 'success' | 'info' | 'warn' | 'error') {
        this.toastService.show({ summary, detail, severity: type, randId: Math.random() });
        // this.messageService.add({ severity: type, summary, detail });
    }

    clearMessages() {
        this.toastService.clearMessages();
    }
}

export interface ToastMessage {
    severity: string,
    summary: string,
    detail: string,
    key?: string,
    life?: number,
}
